<template>
  <div>
       <CAlert
        v-if="formSuccess === 'false'"
        color="danger"
        closeButton
      >
        Invalid form format
      </CAlert>
       <CAlert
        v-if="formSuccess === 'true'"
        color="success"
        closeButton
      >
        File uploaded successfully
      </CAlert>
    <CCard>

      <CCardHeader>
        <CIcon name="cil-grid"/> Buy Health Insurance
      </CCardHeader>
      <CRow class="p-3">
      <CCol sm="12" lg="6" class="p-3">
      <form @submit.prevent="validateForm">
        <h4>Upload an Excel sheet</h4>
        <div class="mt-2">
          <p class="mb-2">Excel File</p>
          <input type="file" ref="file" v-on:change="fileUpload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" required>
          <p v-if="error.file" class="text-danger">Please input a valid file</p>
        </div>
        <button class="mt-4 bg-success text-white rounded p-2" color="border-0 secondary" style="outline: none; border: none; ">Submit</button>
      </form>
      </CCol>
      </Crow>
       
    </CCard> 
  </div>
</template>

<script>
import axios from 'axios'
import url from '@/main'

export default {
  data(){
    return {
      formSuccess: '',
      file: '',
      error:{
        file: false
      }
    }
  },
  watch:{
  
  },
  methods:{
    validateForm(){
      if(Object.values(this.error).includes(true)){
        this.formSuccess = 'false'
        return
      }else{
        this.$store.commit('startLoading');
        let formData = new FormData;
        formData.append('file', this.file);
        axios.post(`${url}/policy/health/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res=>{
          this.$store.commit('stopLoading')
          if(res.status == 200){
            this.$store.commit('formState', true)
            this.$router.push('/policies/inactivehealth')
          }
        })
        .catch(err=>{
          this.$store.commit('stopLoading')
        })
      }
    },
    fileUpload(){
      this.file = this.$refs.file.files[0]
    }
  }
}
</script>

<style>

</style>